import React, { Component } from "react";
import http from '../_api/http'
import {Button, Modal } from 'react-bootstrap'

class NewsLetter extends Component {
    constructor () {
      super()
      this.state = {
        PUBLIC_URL: process.env.REACT_APP_APIURL,
        show:false,
        pdfList:[]
      }
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);   
    }

    componentDidMount(){
      http.get('api/pdf/ApiPdfList?pdf_for_type=17')
      .then(res => {
        if (res.data.status === 200) {
            this.setState({pdfList:res.data.pdf});
        }
      })       
    }
    handleClose =()=> {
        this.setState({show:false})
    }

    handleShow =()=>{
        this.setState({show:true})
    }
    render () {
        return (
          <>
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop='static'
                keyboard={false}
              >
            <Modal.Header closeButton>
            <Modal.Title>NewsLetter</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{"overflow":"auto", "height":"400px"}}>
                <ul className='list-group'>
                      {this.state.pdfList.map((item, i) => (
                        <li key={i} className='list-group-item pdf-list'>
                          <a
                            href={this.state.PUBLIC_URL + 'files/' + item.name}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <h3 className='course-category-one__title model'>
                              {item.pdf_select_for}
                            </h3>
                          </a>
                        </li>
                      ))}
                    </ul>
                </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={this.handleClose}>
                    Close
                  </Button>
            </Modal.Footer>
            </Modal>
            <a href='#' onClick={this.handleShow} style={{color:"white"}}>News Letter</a>
          </>
        )
      }
}
//const nLeter = new NewsLetter();

export default NewsLetter
